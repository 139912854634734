<template>
  <sidebar-menu
    :menu="menu"
    :hideToggle="!mobile"
    :collapsed="mobile"
    :showOneChild="true"
  >
    <template v-slot:header>
      <img src="./assets/logo.svg" alt="Logo" class="logo" />
    </template>
  </sidebar-menu>
  <router-view
    class="router-view"
    :style="`padding-left:` + (mobile ? `65px` : `290px`)"
  />
</template>

<script>
export default {
  data() {
    return {
      mobile: screen.width <= 900,
      menu: [
        {
          title: "Home",
          href: "/",
          icon: "fa fa-home",
        },
        {
          title: "About",
          icon: "fa fa-users",
          child: [
            {
              href: "/about-us",
              title: "About Us",
            },
            {
              href: "/contact",
              title: "Contact Details",
            },
            {
              href: "/referrals",
              title: "Referrals",
            },
          ],
        },
        {
          title: "Services",
          icon: "fa fa-concierge-bell",
          child: [
            {
              href: "/exercise-physiology",
              title: "Exercise Physiology",
            },
            {
              href: "/hydrotherapy",
              title: "Hydrotherapy",
            },
            {
              href: "/clinical-pilates",
              title: "Clinical Pilates",
            },
            {
              href: "/group-programs",
              title: "Group Programs",
            },
            {
              href: "/return-to-work",
              title: "WorkCover / Return to Work",
            },
          ],
        },
        {
          href: "/ndis",
          title: "NDIS",
          icon: "fa fa-sticky-note",
        },
      ],
    };
  },
  created() {
    addEventListener("resize", () => {
      this.mobile = innerWidth <= 900;
    });
  },
};
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

body {
  background-color: #eee;
  margin: 0;
}

.logo {
  padding: 1.5em;
  @media only screen and (max-width: 900px) {
    display: none;
  }
}

.v-sidebar-menu {
  font-family: Inter, Helvetica Neue, Helvetica, Arial, sans-serif;
  -webkit-box-shadow: -2px 5px 50px -2px #000000;
  box-shadow: -2px 5px 50px -2px #000000;
}
</style>
