<template>
  <div class="container">
    <div class="home">
      <h1 id="title">ProHealth Exercise Physiology</h1>
      <h2 id="subtitle">
        A passionate team of exercise physiologists dedicated in providing you
        with the knowledge, support, and tools you need to achieve your desired
        health outcomes through exercise intervention.
      </h2>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.container {
  display: flex;
  height: 100vh;
  justify-content: center;
  align-items: center;
  background-image: url("../assets/home-image.png");
  background-size: cover;
}

.home {
  text-align: center;
  padding-right: 2rem;
  width: 50vw;
  background-color: rgba(255, 255, 255, 80%);
  border-radius: 1rem;
  padding: 5vw;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

#title {
  font-family: Inter, Helvetica Neue, Helvetica, Arial, sans-serif;
  color: #009cdf;
  font-size: 7vw;
  font-weight: bold;
  @media screen and (min-width: 768px) {
    font-size: 4rem;
  }
}

#subtitle {
  font-family: Inter, Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 4vw;
  @media screen and (min-width: 768px) {
    font-size: 1.5rem;
  }
}
</style>
