import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/about-us",
    name: "About Us",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AboutUs.vue"),
  },
  {
    path: "/contact",
    name: "Contact Details",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Contact.vue"),
  },
  {
    path: "/referrals",
    name: "Referrals",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Referrals.vue"),
  },
  {
    path: "/exercise-physiology",
    name: "Exercise Physiology",
    component: () =>
      import(
        /* webpackChunkName: "services" */ "../views/ExercisePhysiology.vue"
      ),
  },
  {
    path: "/hydrotherapy",
    name: "Hydrotherapy",
    component: () =>
      import(/* webpackChunkName: "services" */ "../views/Hydrotherapy.vue"),
  },
  {
    path: "/clinical-pilates",
    name: "Clinical Pilates",
    component: () =>
      import(/* webpackChunkName: "services" */ "../views/ClinicalPilates.vue"),
  },
  {
    path: "/group-programs",
    name: "Group Programs",
    component: () =>
      import(/* webpackChunkName: "services" */ "../views/GroupPrograms.vue"),
  },
  {
    path: "/return-to-work",
    name: "WorkCover / Return to Work",
    component: () =>
      import(/* webpackChunkName: "services" */ "../views/ReturnToWork.vue"),
  },
  {
    path: "/ndis",
    name: "NDIS",
    component: () => import(/* webpackChunkName: "ndis" */ "../views/NDIS.vue"),
  },
  {
    path: "/:catchAll(.*)",
    name: "404",
    component: () => import(/* webpackChunkName: "404" */ "../views/404.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
